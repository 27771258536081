import React, {PureComponent, Suspense, lazy} from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import fire from './fire';
import SplashScreen from './js/component/splashScreen';
import Toast from './js/common/toast';
import {connect} from 'react-redux';
import Footer from './js/component/footer';
import { getToast, getOverlay, getDialog } from './js/store/common/selector';
import LoadingOverlay from './js/common/overlay';
import DialogComponent from './js/common/dialog';
import {Helmet} from "react-helmet";
import {APP_CONFIG} from './appConfig';

const Home = lazy(() => import("./js/container/home"));
const Search = lazy(() => import("./js/container/search"));
const SignUp = lazy(() => import("./js/container/signup"));
const SignIn = lazy(() => import("./js/container/signin"));
const AboutUsDetail = lazy(() => import("./js/container/aboutusdetail"))
const Card = lazy(() => import("./js/container/card"));
const WebsiteView = lazy(() => import("./js/container/webview"));
const ProductDetailsPage = lazy(() => import("./js/container/productDetails"));
const CreateCard = lazy(() => import("./js/container/createcard"));
const MyAccount = lazy(() => import("./js/container/myAccount"));
const ShowMyReferal = lazy(() => import("./js/container/showMyReferal"));
const PreviewTemplate = lazy(() => import("./js/container/previewTemplate"));

class AppRouter extends PureComponent{

    componentDidMount(){
        this.authListener()
    }

    authListener =() =>{
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                localStorage.setItem('user', user);
            } else {
                localStorage.removeItem(user);
            }
          });
    }

    isFooterVisible = () =>{
        if(window.location.pathname.indexOf("business")> -1 || window.location.pathname.indexOf("preview")> -1 ){
            return false
        }else{
            return true;
        }
    }
  
    render(){
        const { toast, overlay, dialog } = this.props;
        const isFooter = this.isFooterVisible();
        return(<div>
             <Helmet>
                <meta charSet="utf-8" />
                <title>{APP_CONFIG.title}</title>
                <meta name="description" content={APP_CONFIG.description} />
                <meta name="og:url" content={APP_CONFIG.API_URL} />
                <meta name="og:description" content={APP_CONFIG.description} />
                <meta name="og:title" content={APP_CONFIG.title} />
                <meta name="og:keywords" content={APP_CONFIG.keywords} />
                <meta name="keywords" content={APP_CONFIG.keywords} />                        
                <meta name="twitter:keywords" content={APP_CONFIG.keywords}/>
                <meta name="og:image" content={APP_CONFIG.image} />
                <meta name="og:title" content={APP_CONFIG.title} />
                <meta name="og:type" content="website" />

                <meta name="twitter:title" content={APP_CONFIG.title} />
                <meta name="twitter:description" content={APP_CONFIG.description}/>
                <meta name="twitter:image" content={APP_CONFIG.image} />
                <meta name="twitter:card" content={APP_CONFIG.description} />
            </Helmet>
            {toast.status && <Toast/>}
            {overlay && <LoadingOverlay/>}
            {dialog && <DialogComponent/>}
                <div style={ {minHeight: '89.5vh'}}>
                <Router>
                    <Suspense fallback={<SplashScreen/>}>
                        <Route exact path="/" component={Home}></Route>
                        <Route exact path="/search" component={Search}></Route>
                        <Route exact path="/sign-up" component={SignUp}></Route>
                        <Route exact path="/about-us" component={AboutUsDetail}></Route>                   
                        <Route exact path="/sign-in" component={SignIn}></Route>
                        <Route exact path="/business-card/:cardUrl" component={Card}></Route>
                        <Route exact path="/business/:cardUrl" component={WebsiteView}></Route>
                        <Route exact path="/business/:cardUrl/offerings/:offerings/:productId" component={ProductDetailsPage}></Route>
                        <Route exact path="/create-card/:cardUrl" component={CreateCard}></Route>
                        <Route exact path="/create-card" component={CreateCard}></Route>
                        <Route exact path="/my-profile" component={MyAccount}></Route>
                        <Route exact path="/my-referrals/:cardUrl" component={ShowMyReferal}></Route>
                        <Route exact path="/preview/:cardUrl/:template/" component={PreviewTemplate}></Route>
                        <Route exact path="/my-referrals" component={ShowMyReferal}></Route>

                    </Suspense>
                </Router>
                </div>
             {isFooter && (<Footer />)} 
            </div>)
    }
}

const mapSateToProps = state =>{
    return {
        toast: getToast(state),
        overlay: getOverlay(state),
        dialog: getDialog(state)
    }
}

export default connect(
    mapSateToProps,
    {}
)(AppRouter);
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import { APP_CONFIG } from './appConfig';

let fire;
if (APP_CONFIG.ENV === 'PROD') {
  let firebaseConfig = {
    apiKey: 'AIzaSyBvQKTmsbIn35spVgfWTZhUxhu8fNI45hM',
    authDomain: 'digicards-1578072101636.firebaseapp.com',
    databaseURL: 'https://digicards-1578072101636.firebaseio.com',
    projectId: 'digicards-1578072101636',
    storageBucket: 'digicards-1578072101636.appspot.com',
    messagingSenderId: '1077382285119',
    appId: '1:1077382285119:web:c81109b397de60b631358a',
    measurementId: 'G-544Y28VXYZ'
  };
  // Initialize Firebase
  fire = firebase.initializeApp(firebaseConfig);
  firebase.analytics();
} else {
  // Your web app's Firebase configuration
  let firebaseConfig = {
    apiKey: 'AIzaSyBHzDxeu0xwSn7-XK6eksUKYNsuy72r5Yo',
    authDomain: 'digicards-dev.firebaseapp.com',
    databaseURL: 'https://digicards-dev.firebaseio.com',
    projectId: 'digicards-dev',
    storageBucket: 'digicards-dev.appspot.com',
    messagingSenderId: '526123829358',
    appId: '1:526123829358:web:1d3734e95aebeb769d7e7f',
    measurementId: 'G-4NJPSVVVT1'
  };
  // Initialize Firebase
  fire = firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

export default fire;

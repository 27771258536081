/* eslint-disable indent */
import {
  SET_OVERLAY,
  SET_LOGGED_IN_USER,
  MANAGE_DIALOG,
  MANAGE_TOAST,
  MANAGE_PRODUCT_SERVICE_FULL_DISPLAY_DIALOG,
  SET_USER_ANALYTICS_DETAILS,
  SET_COMMUNITY_ANALYTICS_DETAILS,
  SET_COMPONENT_OVERLAY,
  MANAGE_CARD_ANALYTICS_MODAL
} from '../../actions/actionTypes';
import { post, get } from '../../axiosUtils/appUtils';

export const setOverlayStatus = data => {
  return {
    type: SET_OVERLAY,
    payload: data
  };
};

export const setComponentOverlay = data => {
  return {
    type: SET_COMPONENT_OVERLAY,
    payload: data
  };
};

export const setLoggedInUser = data => {
  return {
    type: SET_LOGGED_IN_USER,
    payload: data
  };
};

export const setUserAnalyticsDetails = data => {
  return {
    type: SET_USER_ANALYTICS_DETAILS,
    payload: data
  };
};

export const setCommunityAnalyticsDetails = data => {
  return {
    type: SET_COMMUNITY_ANALYTICS_DETAILS,
    payload: data
  };
};

export const manageToast = data => {
  return {
    type: MANAGE_TOAST,
    payload: data
  };
};

export const manageDialog = data => {
  return {
    type: MANAGE_DIALOG,
    payload: data
  };
};

export const manageProductServiceFullDisplayDialog = data => {
  return {
    type: MANAGE_PRODUCT_SERVICE_FULL_DISPLAY_DIALOG,
    payload: data
  };
};

export const manageCardSelectionModal = data => {
  return {
    type: MANAGE_CARD_ANALYTICS_MODAL,
    payload: data
  };
};

export const registerUser = (data, flag) => {
  return dispatch => {
    dispatch(setOverlayStatus(true));
    return post(`digicard_backend/webservices/api/public/user/userRegistration`, data)
      .then(response => {
        if (response.data.status.toUpperCase() === 'SUCCESS') {
          const obj = {
            title: 'success',
            message: 'User Registration has been successfully done',
            status: true,
            type: 'success'
          };
          dispatch(manageToast(obj));
          if(flag){
            window.location.href="/create-card";
          }else{
          window.location.href="/my-profile";
          }
          if (response.data.data !== null) {
            sessionStorage.setItem('userId', response.data.data.userId);
            sessionStorage.setItem('emailId', data.email);
          }
        } else {
          const obj = {
            title: 'error',
            message: response.data.message,
            status: true,
            type: 'error'
          };
          dispatch(manageToast(obj));
          dispatch(setOverlayStatus(false));
        }
        dispatch(setOverlayStatus(false));
      })
      .catch(error => {
        const obj = {
          title: 'error',
          message: error.data.message,
          status: true,
          type: 'error'
        };
        dispatch(manageToast(obj));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const saveContactUs = data => {
  return dispatch => {
    dispatch(setOverlayStatus(true));
    return post(`digicard_backend/webservices/mail.php`, data)
      .then(response => {
        if (response.data.status.toUpperCase() === 'SUCCESS') {
          const obj = {
            title: 'success',
            message: 'Thank you for your message. We will contact you shortly!',
            status: true,
            type: 'success'
          };
          dispatch(manageToast(obj));
        } else {
          const obj = {
            title: 'error',
            message: response.data.message,
            status: true,
            type: 'error'
          };
          dispatch(manageToast(obj));
        }
        dispatch(setOverlayStatus(false));
      })
      .catch(error => {
        const obj = {
          title: 'error',
          message: error && error.data ? error.data.message : 'Something went wrong. Please try later',
          status: true,
          type: 'error'
        };
        dispatch(manageToast(obj));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getUserInfo = () => {
  return dispatch => {
    dispatch(setOverlayStatus(true));
    return get(
      `digicard_backend/webservices/api/public/user/getUserDetails/${sessionStorage.getItem(
        'emailId'
      )}`
    )
      .then(response => {
        if (response.data.status.toUpperCase() === 'SUCCESS') {
          sessionStorage.setItem('userinfo',JSON.stringify(response.data.data));
          dispatch(setLoggedInUser(response.data.data));
          dispatch(setOverlayStatus(false));
        } else {
          const obj = {
            title: 'error',
            message:
              'Details You are trying to get has some issue.Please try later',
            status: true,
            type: 'error'
          };
          dispatch(manageToast(obj));
        }
      })
      .catch(error => {
        console.log(error);
        const obj = {
          title: 'error',
          message: error.message,
          status: true,
          type: 'error'
        };
        dispatch(manageToast(obj));
        dispatch(setOverlayStatus(false));
      });
  };
};

// http://digicards-dev.tk/digicard_backend/webservices/api/public/card/service/analytics/share/ jigs.o.1227@gmail.com
export const getUserAnalytics = () => {
  return dispatch => {
    dispatch(setOverlayStatus(true));
    dispatch(setComponentOverlay(true));
    return post(
      `digicard_backend/webservices/api/public/card/service/analytics/share/${sessionStorage.getItem(
        'emailId'
      )}`
    )
      .then(response => {
        if (response.data.status.toUpperCase() === 'SUCCESS') {
          dispatch(setUserAnalyticsDetails(response.data.data));
        } else {
          const obj = {
            title: 'error',
            message:
              'Could not find analysis details. Please try again later!!',
            status: true,
            type: 'error'
          };
          dispatch(manageToast(obj));
        }
        dispatch(setComponentOverlay(false));
        dispatch(setOverlayStatus(false));
      })
      .catch(error => {
        console.log(error);
        const obj = {
          title: 'error',
          message: error.message,
          status: true,
          type: 'error'
        };
        dispatch(manageToast(obj));
        dispatch(setComponentOverlay(false));
        dispatch(setOverlayStatus(false));
      });
  };
};

// http://digicards-dev.tk/digicard_backend/webservices/api/public/card/service/analytics/jigs.o.1227@gmail.com
export const getCommunityAnalytics = () => {
  return dispatch => {
    dispatch(setOverlayStatus(true));
    dispatch(setComponentOverlay(true));
    return post(
      `digicard_backend/webservices/api/public/card/service/analytics/${sessionStorage.getItem(
        'emailId'
      )}`
    )
      .then(response => {
        if (response.data.status.toUpperCase() === 'SUCCESS') {
          dispatch(setCommunityAnalyticsDetails(response.data.data));
        } else {
          const obj = {
            title: 'error',
            message:
              'Could not find analysis details. Please try again later!!',
            status: true,
            type: 'error'
          };
          dispatch(manageToast(obj));
        }
        dispatch(setOverlayStatus(false));
        dispatch(setComponentOverlay(false));
      })
      .catch(error => {
        console.log(error);
        const obj = {
          title: 'error',
          message: error.message,
          status: true,
          type: 'error'
        };
        dispatch(manageToast(obj));
        dispatch(setOverlayStatus(false));
        dispatch(setComponentOverlay(false));
      });
  };
};

export const APP_CONFIG = {};

APP_CONFIG.ENV = 'PROD'; //PROD or DEV

if (APP_CONFIG.ENV === 'DEV') {
  APP_CONFIG.API_URL = 'http://digicards-dev.ml';
} else {
  APP_CONFIG.API_URL = 'https://www.digicards.me';
}

APP_CONFIG.description = 'Digicards provide a one-stop solution for all your digital needs at the most economical pricing. Our innovative solution can serve all kinds of requirements in just 10 minutes. Our solution suits all kind of your requirements be it website, be it invitation cards, be it events or party invite. Our vision is to make Digital Presence the most affordable solution for everyone.';
APP_CONFIG.image = './assets/images/img/favicon.png';
APP_CONFIG.title = 'Digicards - Digital Identity';
APP_CONFIG.keywords = 'Digicards,Digital Identity, Digital business card, Digital,domain, website, digital card, visiting card,one page website, hosting ';

APP_CONFIG.theme1 = "Royal White";
APP_CONFIG.theme2 = "Dark Delight";
APP_CONFIG.theme3 = "Sea Green Gem";
APP_CONFIG.theme4 = "Orange Gem";
APP_CONFIG.theme5 = "Blue Gem";
APP_CONFIG.theme6 = "Cool Tiles";
APP_CONFIG.theme7 = "Rubix";
APP_CONFIG.theme8 = "Exhaustive"

APP_CONFIG.BROCHURE = 'BROCHURE';

APP_CONFIG.PRIMARY_COLOR = '#55d8f2';
APP_CONFIG.SECONDARY_COLOR = '#373738';


APP_CONFIG.currencies = [
    {
        value: 'INR',
        label: '₹'
    },  
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'JPY',
      label: '¥',
    }
  ];

import { combineReducers } from 'redux';
import CommonReducer from '../store/common/commonReducer';
import CardReducer from '../store/card/cardReducer';
import SearchReducer from '../store/search/searchReducer';

const rootReducer = combineReducers({
    CommonReducer,
    CardReducer,
    SearchReducer
})
export default rootReducer;
import React from 'react';

const SplashScreen = () => {
  
  const splashScreenLogo = {
    width: "15em",
    position:"absolute",
    top:"50%",
    // left:"44%"
    left: "50%",
    margin: "-7.5em 0em 0em -7.5em"
  }
  return (<img src="../../../assets/images/img/digi-logo-final.png" style={splashScreenLogo} alt="logo"/>);
}
export default SplashScreen;
const styles = (theme) =>{
    return {
          designerText: {
              color: '#fff'
          },
          footerLeftText:{
            color:'#dddbdb',
            fontSize: '14px'
          },
          link:{
            color: 'rgb(255, 141, 87)',
            fontSize: '16px',
            fontWeight: '600',
            paddingLeft: '7px',
            paddingRight: '7px'
          },
          footer: {
            padding: '16px',
            marginTop: 'auto',
            backgroundColor: theme.palette.grey[800],
        },
        footerIcons: {
            fontSize: '1.7em'
        }
    }
}
export default styles;
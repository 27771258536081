import {
  SET_CARD_DETAILS,
  SET_MY_ALL_CARDS,
  REFER_A_FRIEND_TO_BUSINESS,
  SET_MY_CARD_REFERRAL_INFO
} from '../../actions/actionTypes';

const getInitialState= () =>({
    cardDetails: null,
    myAllCards: null,
    referAFriendToBusinessDialog: {
      cardId: '',
      status: false,
      type: ''
    },
    referralInfo: null
});

export default function cardReducer(state = getInitialState(), action) {
    switch (action.type) {
      case SET_CARD_DETAILS:
        return Object.assign({},state,{
            cardDetails: action.payload
        });
      case SET_MY_ALL_CARDS:
        return Object.assign({},state,{
          myAllCards: action.payload
        });
      case REFER_A_FRIEND_TO_BUSINESS:
        return Object.assign({}, state,{
          referAFriendToBusinessDialog: action.payload
        });
      case SET_MY_CARD_REFERRAL_INFO:
        return Object.assign({}, state,{
          referralInfo: action.payload
        });
        default:
        return state
    }
  }
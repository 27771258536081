const styles = () =>{
    return{
        center:{
            position:'fixed',
            top:'47%',
            left:'47%'        
        },
        overlay: {
            position:'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            background: '#ffddcd',
            opacity:'0.5',
            zIndex: 10000,
        }
    }
}
export default styles;
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const LoadingOverlay = props => {
    const {classes} = props;
    const {overlay, center} = classes;

  return (<div className={overlay}> 
        <div className={center}>
        <CircularProgress color="secondary"/>
        </div>
    </div>
  );
}
export default withStyles(styles)(LoadingOverlay);
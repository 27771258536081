import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { manageDialog } from '../../store/common/action';
import { getDialog } from '../../store/common/selector';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogComponent() {
  const dispatch = useDispatch();
  const dialog = useSelector(state => getDialog(state))
  const handleClose = () => {
    const reqObj ={
      title:'',
      message: '',
      status: false,
      type: ''
    }
    dispatch(manageDialog(reqObj));
  };

  const handleSubmit =() =>{
    const callback = typeof dialog.onClose === 'function' ? dialog.onClose() : null;
    
    const reqObj ={
      title:'',
      message: '',
      status: false,
      type: ''
    }
    dispatch(manageDialog(reqObj));
  }

  return (
      <Dialog
        open={dialog.status}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           {dialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Disagree
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
  );
}
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

const Footer = (props) => {
    const { classes } = props;

    const isDesktop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    });

    return (
        <footer className={classNames(classes.footer, "app-footer")}>
            <Grid container direction= {isDesktop ? "row" : "column-reverse"}
                  justify={isDesktop ? "space-around": "center"}
                  alignItems="center">
                      <Col sm={6} className={classNames("mt-4")}>
                          <Typography variant="body2" component="div" align={isDesktop ? "left" : "center"} className={classes.designerText}>
                            Designed and Developed By 
                            {!isDesktop && <br/>}
                            <a className={classNames(classes.link)} href="https://www.digicards.me"  target="_blank">
                                DigiCards
                            </a>
                        </Typography>    
                      </Col>
                      <Col sm={6}> 
                      <Grid
                        container
                        direction="row"
                        justify={isDesktop ? "flex-end": "center"}
                        alignItems="center"
                        >
                            <a href="https://www.youtube.com/channel/UC15FJCV8ybFVscvzTlS3zRg" className={classNames(classes.link)} target="_blank">
                                <YouTubeIcon className="linkedInIconColor" className={classes.footerIcons}/>
                            </a>
                            <a href="https://www.instagram.com/digicards.me/" className={classNames(classes.link)} target="_blank">
                                <LinkedInIcon className="linkedInIconColor" className={classes.footerIcons}/>
                            </a>
                            <a href="https://www.instagram.com/digicards.me/" className={classNames(classes.link)} target="_blank">
                                <InstagramIcon className="instagramIconColor" className={classes.footerIcons}/>
                            </a>
                            <a href="https://www.facebook.com/digicards.me/" className={classNames(classes.link)} target="_blank">
                                <FacebookIcon className="facebookIconColor" className={classes.footerIcons}/>
                            </a>

                        </Grid>
                      </Col>
            </Grid>
        </footer>
    );
}
export default withStyles(styles)(Footer);
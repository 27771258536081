export const SET_OVERLAY = 'SET_OVERLAY';
export const MANAGE_TOAST = 'MANAGE_TOAST';
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const SET_CARD_DETAILS = 'SET_CARD_DETAILS';
export const SET_MY_ALL_CARDS = 'SET_MY_ALL_CARDS';
export const MANAGE_DIALOG = 'MANAGE_DIALOG';
export const MANAGE_PRODUCT_SERVICE_FULL_DISPLAY_DIALOG = 'MANAGE_PRODUCT_SERVICE_FULL_DISPLAY_DIALOG';
export const REFER_A_FRIEND_TO_BUSINESS = 'REFER_A_FRIEND_TO_BUSINESS';
export const SET_MY_CARD_REFERRAL_INFO = 'SET_MY_CARD_REFERRAL_INFO';
export const SET_FILTERED_CARD_DETAILS = 'SET_FILTERED_CARD_DETAILS';
export const SET_USER_ANALYTICS_DETAILS = 'SET_USER_ANALYTICS_DETAILS';
export const SET_COMMUNITY_ANALYTICS_DETAILS = 'SET_COMMUNITY_ANALYTICS_DETAILS';
export const SET_COMPONENT_OVERLAY = 'SET_COMPONENT_OVERLAY';
export const MANAGE_CARD_ANALYTICS_MODAL = 'MANAGE_CARD_ANALYTICS_MODAL';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './appRouter';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../src/js/reducers/index';
import { composeWithDevTools } from 'redux-devtools-extension';
import 'bootstrap/dist/css/bootstrap.min.css';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { createMuiTheme,ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: 'rgb(255, 141, 87)',
        light: 'rgb(254, 180, 123)',
        dark : 'rgb(255, 126, 95)',
      },
      secondary: blueGrey,
    },
    status: {
      danger: 'red',
    },
  }
);
theme = responsiveFontSizes(theme);

const initialState = {}
const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  );

ReactDOM.render(<Provider store={store}>
                <ThemeProvider theme={theme}>
                <AppRouter />
                </ThemeProvider>
      </Provider>, document.getElementById('root'));
    
serviceWorker.unregister()

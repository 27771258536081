import {
  SET_OVERLAY,
  SET_LOGGED_IN_USER,
  MANAGE_DIALOG,
  MANAGE_TOAST,
  MANAGE_PRODUCT_SERVICE_FULL_DISPLAY_DIALOG,
  SET_USER_ANALYTICS_DETAILS,
  SET_COMMUNITY_ANALYTICS_DETAILS,
  SET_COMPONENT_OVERLAY,
  MANAGE_CARD_ANALYTICS_MODAL
} from '../../actions/actionTypes';

const getInitialState= () =>({
    overlayStatus: false,
    componentOverlayStatus: false,
    userDetails: null,
    userAnalyticsDetails: null,
    communityAnalytucsDetails: null,
    cardAnalyticsModal: {
      isCardAnalyticsClicked: false,
      cardAnalyticsDetails: null
    },
    toast : {
      title:'',
      message: '',
      status: false,
      type: ''
    },
    dialog:{
      title:'',
      message: '',
      status: false,
      type: ''
    },
    productServiceDialog: {
      title: '',
      description: '',
      imgUrl: '',
      status: false,
      type: ''
    }
});

export default function commonReducer(state = getInitialState(), action) {
    switch (action.type) {
      case SET_OVERLAY:
        return Object.assign({},state,{
            overlayStatus: action.payload
        });
      case SET_COMPONENT_OVERLAY:
        return Object.assign({}, state, {
            componentOverlayStatus: action.payload
        });
      case SET_LOGGED_IN_USER:
        return Object.assign({},state,{
            userDetails: action.payload
        });
      case MANAGE_TOAST:
        return Object.assign({},state,{
          toast: action.payload
        });
      case MANAGE_DIALOG:
        return Object.assign({},state,{
          dialog: action.payload
      });
      case MANAGE_CARD_ANALYTICS_MODAL:
        return Object.assign({},state,{
          cardAnalyticsModal: action.payload
      });
      case MANAGE_PRODUCT_SERVICE_FULL_DISPLAY_DIALOG:
        return Object.assign({}, state,{
          productServiceDialog: action.payload
        });
      case SET_USER_ANALYTICS_DETAILS:
        return Object.assign({}, state,{
          userAnalyticsDetails: action.payload
        });
      case SET_COMMUNITY_ANALYTICS_DETAILS: {
        return Object.assign({}, state,{
          communityAnalytucsDetails: action.payload
        });
      }
      default:
      return state
    }
  }
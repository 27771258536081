import { SET_FILTERED_CARD_DETAILS } from '../../actions/actionTypes';

const getInitialState = () => ({
    filteredCardDetails: null
});

export default function searchReducer(state = getInitialState(), action) {
    switch (action.type) {
        case SET_FILTERED_CARD_DETAILS:
            return Object.assign({}, state, {
                filteredCardDetails: action.payload
            });
        default:
        return state
    }
}